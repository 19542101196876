import Layout from '../components/Layout';
import Container from '../components/Container';
import { StaticImage } from 'gatsby-plugin-image';

import { css } from '@emotion/react';
import { commonStyles, spacing } from '../config/styles';
import { useEffect, useState } from 'react';

const styles = {
  titlesWrapper: css`
    margin-top: ${spacing['40']};
    margin-bottom: ${spacing['40']};
    text-align: center;
  `,
  titles: css`
    ${commonStyles.h3}
    margin-bottom: ${spacing['20']};
  `,
  photo: (isVisible: boolean) => css`
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;

    ${isVisible &&
    css`
      display: none;
    `}
  `,
};
const photos = [
  <StaticImage src="../images/team-photo-2021-22.jpeg" alt="Ismegink.lt" placeholder="blurred" key={'2021-2022'} />,
  <StaticImage src="../images/team-2019-2020.jpg" alt="Ismegink.lt" placeholder="blurred" key={'2019-2020'} />,
];
const IndexPage = () => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((value) => (value + 1 <= photos.length - 1 ? value + 1 : 0));
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Layout>
      <Container>
        <section>
          <div>
            {photos.map((item, index) => {
              return (
                <div key={item.key} css={styles.photo(current === index)}>
                  {item}
                </div>
              );
            })}
          </div>
          <div css={styles.titlesWrapper}>
            <h3 css={styles.titles}>2021-2022 metų Aitvaro krepšinio turnyras - 3 vieta</h3>
            <h3 css={styles.titles}>2019-2020 metų Aitvaro krepšinio turnyras - 2 vieta</h3>
            <h3 css={styles.titles}>2017-2018 metų Aitvaro krepšinio turnyras - 1 vieta</h3>
            <h3 css={styles.titles}>2018-2019 metų VKL Top Sport Pavasario čempionato 4 lygos - 2 vieta</h3>
            <h3 css={styles.titles}>2015-2016 metų VKL draudimas.lt pavasario čempionatas - Išūkio taurė - 1 vieta</h3>
          </div>
        </section>
      </Container>
    </Layout>
  );
};

export default IndexPage;
